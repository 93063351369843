<template>
  <div class="insert-btn-cont">
    <component
      :is="currentView"
      :show="showTemplate"
      :account-id="channel ? channel.id : null"
      @close="handleClose"
      @resourceSuccess="handleSelectSuccess"
    ></component>
  </div>
</template>

<script>
import YueWenDialog from '../../service/insertBook/yuewen'
import ReadDialog from '../../service/insertBook/read'
import SunshineDialog from '../../service/insertBook/sunshine'
import BookHouseDialog from '../../service/insertBook/bookhouse'
import QiYueDialog from '../../service/insertBook/qiyue'
import HuaShengDialog from '../../service/insertBook/huasheng'
import HaiDu from '../../service/insertBook/haidu'
import WangYiDialog from '../../service/insertBook/wangyi'
import KanShuWangDialog from '../../service/insertBook/kanshuwang'

export default {
  name: 'insertBook',
  props: {
    channel: Object,
    showBook: Boolean
  },
  data() {
    return {
      showTemplate: false,
      currentView: null
    }
  },
  components: {
    HaiDu,
    ReadDialog,
    QiYueDialog,
    WangYiDialog,
    YueWenDialog,
    SunshineDialog,
    BookHouseDialog,
    HuaShengDialog,
    KanShuWangDialog,
  },
  watch: {
    showBook: function (newValue) {
      if (newValue) {
        this.handleDialogShow()
      }
    }
  },
  methods: {
    handleDialogShow() {
      if (this.channel) {
        const {platform} = this.channel
        switch (platform) {
          case 0:
            this.currentView = 'ReadDialog'
            break
          case 1:
            this.currentView = 'YueWenDialog'
            break
          case 3:
            this.currentView = 'SunshineDialog'
            break
          case 4:
            this.currentView = 'BookHouseDialog'
            break
          case 5:
            this.currentView = 'WangYiDialog'
            break
          case 6:
            this.currentView = 'QiYueDialog'
            break
          case 7:
            this.currentView = 'HuaShengDialog'
            break
          case 8:
            this.currentView = 'HaiDu'
            break
          case 12:
            this.currentView = 'WangYiDialog'
            break
          case 14:
            this.currentView = 'KanShuWangDialog'
            break
        }
        this.showTemplate = true
      } else {
        this.$message.warning('请选择公众号')
      }
    },
    handleClose() {
      this.showTemplate = false
      this.$emit('close')
    },
    handleSelectSuccess(select) {
      this.$emit('success', select)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;

  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.insert-btn-cont {
  display: inline-block;
}
</style>
