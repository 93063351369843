<template>
  <div class="ground-content">
    <div
      class="ground-table border-radius-6 box-shadow-light-grey padding-20"
      style="padding-top: 16px"
    >
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        :loading="loading"
        :showPagination="true"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        height="650px"
        :isUseCheckbox="true"
        @selection-change="handleSelectChange"
        @pageChange="handlePageChange"
      >
        <template #filter>
          <el-button class="margin-right-ten" @click="handlePageChange"
            >刷新数据</el-button
          >
          <el-button
            type="primary"
            class="margin-right-ten"
            :disabled="!deleteIds.length"
            @click="handleUpload"
            >批量上传</el-button
          >
          <!-- <el-input
            v-model="aid"
            style="width: 200px; margin-left: 10px"
            placeholder="广告计划id"
            clearable
            @clear="aid = null"
          >
            ></el-input
          >
          <el-input
            v-model="cid"
            style="width: 200px; margin-left: 10px"
            placeholder="广告创意 id"
            clearable
            @clear="cid = null"
          >
            ></el-input
          >
          <el-button
            type="primary"
            class="margin-left-ten"
            @click="handlePageChange"
            >搜索</el-button
          > -->
        </template>
        <template #status="{ row }">
          {{ row.status ? "已上传" : "未上传" }}
        </template>
        <template #operation="{ row }">
          <el-button
            type="primary"
            size="small"
            class="button-small"
            @click="handleUploadSingle(row.id)"
            >上传</el-button
          >
        </template>
      </dy-table>
    </div>
  </div>
</template>

<script>
import { landingPageDetail, uploadLandingPageDetail } from "@/api/groundPage";
export default {
  props: ["detail", "landingType"],
  data() {
    return {
      loading: false,
      rowList: [
        {
          label: "id",
          prop: "id",
        },
        {
          label: "openId",
          prop: "openId",
          popover: true,
        },
        {
          label: "创意id",
          prop: "cid",
        },
        {
          label: "计划id",
          prop: "aid",
          renderHeader: () => <span id="aidName">计划id</span>,
        },
        {
          label: "订单金额",
          prop: "amount",
        },
        {
          label: "回传url",
          prop: "url",
          popover: true,
        },
        {
          label: "上传状态",
          prop: "status",
          colWidth: "80",
          slot: true,
        },
        {
          label: "上传时间",
          prop: "createdTime",
          colWidth: "160",
        },
        {
          label: "操作",
          action: "operation",
        },
      ],
      dataList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      accountChannelId: null,
      showEdit: false,
      isEdit: false,
      formParams: {},
      editId: null,
      timeRange: ["09:00:00", "22:00:00"],
      deleteIds: [],
      params: {},
      deleteIds: [],
      cid: null,
      aid: null,
      cidName: "计划id",
    };
  },
  methods: {
    handleUploadSingle(id) {
      uploadLandingPageDetail(id).then((res) => {
        this.$message.success("上传成功");
        this.handlePageChange();
      });
    },
    handleUpload() {
      const tmpids = this.deleteIds.map((item) => item.id) || [];
      uploadLandingPageDetail(tmpids.join(",")).then((res) => {
        this.$message.success("上传成功");
        this.handlePageChange();
      });
    },
    handleSelectChange(item) {
      this.deleteIds = item;
    },
    handlePageChange(page) {
      this.loading = true;
      this.page = typeof page === "number" ? page : this.page;
      landingPageDetail(this.detail.id, {
        page: this.page,
        pageSize: this.pageSize,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.landingType == 2) {
      this.cidName = "单元id";
    }
    this.handlePageChange();
  },
  mounted() {
    // 根据 type修改计划id或单元id
    this.$nextTick(() => {
      document.querySelector("#aidName").innerHTML = this.cidName;
    });
  },
};
</script>

<style lang="scss" scoped>
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
.ground-content {
  height: 100%;
  .operation {
    background: #fff;
    box-sizing: border-box;
    // padding:  0 20px;
  }
  .ground-table {
    background: #fff;
  }
}
</style>
