<template>
  <el-dialog
    title="选择小说"
    width="800px"
    top="0vh"
    append-to-body
    v-dialogDrag
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div>
      <el-input
        placeholder="小说ID"
        v-model="cbid"
        class="input-with-select"
        @keydown.enter.native="handleNovelSearch"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleNovelSearch"
        ></el-button>
      </el-input>
      <div class="search-history">
        <div class="search-history-operation" v-if="historySearchList.length">
          <h3>搜索历史</h3>
          <div class="delete-history" @click="deleteHistory">
            <i class="el-icon-delete-solid"></i>
            <span>清除历史记录</span>
          </div>
        </div>
        <div class="search-history-list" v-if="historySearchList.length">
          <div
            v-for="(bookInfo, index) in historySearchList.slice(-5)"
            :key="index"
            style="margin-right: 15px; margin-bottom: 15px"
          >
            <div v-if="'book_id' in bookInfo">
              <el-button
                round
                style="padding: 10px"
                @click="chooseHistoryBook(bookInfo)"
              >{{ bookInfo.book_title }}
              </el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-table
        :data="bookList"
        height="550px"
        v-loading="bookLoading"
        highlight-current-row
        @current-change="(row) => (this.currentBook = row)"
        style="width: 100%"
      >
        <el-table-column prop="select" label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              type="radio"
              :value="currentBook ? currentBook.book_id : null"
              :label="scope.row.book_id"
              style="font-size: 0"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column property="book_id" label="小说ID" width="200">
        </el-table-column>
        <el-table-column property="book_title" label="小说名称" min-width="120">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-button :disabled="page === 1" @click="handlePageChange('left')"
        >上一页
        </el-button
        >
        <span class="page">第 {{ page }} 页</span>
        <el-button @click="handlePageChange('right')" :disabled="noMoreNovel"
        >下一页
        </el-button
        >
      </div>

      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleNext">下一步</el-button>
      </div>

      <!--选择章节-->
      <el-dialog
        title="选择章节"
        :visible.sync="showNext"
        width="800px"
        top="5vh"
        append-to-body
        :before-close="() => (this.showNext = false)"
      >
        <el-table
          :data="chapterList"
          height="650px"
          v-loading="chapterLoading"
          highlight-current-row
          @current-change="(row) => (this.currentChapter = row)"
          style="width: 100%"
        >
          <el-table-column prop="select" label="选择" width="50">
            <template slot-scope="scope">
              <el-radio
                type="radio"
                :value="currentChapter ? currentChapter.content_id : null"
                :label="scope.row.content_id"
                style="font-size: 0"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column property="content_id" label="章节ID" width="170">
          </el-table-column>
          <el-table-column property="title" label="章节名称" min-width="120">
          </el-table-column>
          <el-table-column property="order" label="章节" min-width="100">
          </el-table-column>
        </el-table>
        <div class="dialog-footer">
          <el-button @click="showNext = false">取 消</el-button>
          <el-button type="primary" @click="handleSure" :loading="submitLoading"
          >确 定
          </el-button
          >
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script>
import UserAvatar from "../../../components/UserAvatar";
import {getBookList, getBookChapter} from "../../../api/service";
import {deduplication} from "./../../../assets/js/utils";
import {mapMutations} from "vuex";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";

export default {
  name: "insertBook",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    accountId: Number,
  },
  data() {
    return {
      page: 1,
      chapterPage: 1,
      chapterTitle: null,
      cbid: null,
      title: null,
      bookList: [],
      bookLoading: false,
      currentBook: null,
      showNext: false,
      chapterList: [],
      chapterLoading: false,
      currentChapter: {},
      submitLoading: false,
      noMoreNovel: false,
      noMoreChapter: false,
    };
  },
  computed: {
    historySearchList() {
      const { kanShuWang = [] } = this.$store.state.historyBookCache || {}
      return [...kanShuWang]
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.handleNovelSearch();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setHistoryBookCache"]),
    handleNovelSearch() {
      this.page = 1;
      this.getBookData();
    },
    handleSure() {
      const {content_id, title} = this.currentChapter;
      const {book_id, book_title} = this.currentBook;
      if (content_id) {
        this.submitLoading = true;
        // this.handleClose();
        this.showNext = false;
        // <a href="{booklink:22187493000680502,content_id:96067135545295515}">踏过星光说爱你</a>
        let name = book_title + "$" + (content_id ? title : "");
        const url = content_id
          ? `{booklink:${book_id},ccid:${content_id},bookName:${Base64.stringify(
            Utf8.parse(name)
          )}}`
          : `{booklink:${book_id}}`;
        name = book_title + " " + (content_id ? title : "");
        this.$emit("success", {
          link: `<a href="${url}">${name}</a>`,
          name,
          url,
        });
        // 图文素材返回
        let nexChapterIndex =
          this.chapterList.findIndex((item) => {
            return item.content_id === content_id;
          }) + 1;
        this.$emit("resourceSuccess", {
          book: {
            bookId: book_id,
            bookName: book_title + "$" + title,
          },
          chapter: {
            title: title,
            bookArticleId: this.chapterList[nexChapterIndex].content_id,
            curChapterId: content_id,
            bookArticleIdx: nexChapterIndex - 1,
            endIdx: nexChapterIndex
          },
        });
        this.submitLoading = false;
        this.handleClose();
        this.showNext = false;
      } else {
        this.$message.warning("请选择小说章节");
      }
    },
    handleNext() {
      if (this.currentBook) {
        this.chapterTitle = null;
        this.showNext = true;
        const historyBookList = this.historySearchList.length
          ? [...this.historySearchList]
          : [];
        historyBookList.push({...this.currentBook});
        const res = deduplication(historyBookList);
        this.setHistoryBookCache({
          name: "kanShuWang",
          value: res,
        });
        this.handleSearch();
      } else {
        this.$message.warning("请选择一篇小说！");
      }
    },
    handleSearch() {
      this.chapterPage = 1;
      this.getChapterData();
    },
    getChapterData() {
      this.chapterLoading = true;
      getBookChapter(this.accountId, {
        cbid: this.currentBook.book_id,
        page: this.chapterPage,
        title: this.chapterTitle,
      })
        .then((res) => {
          this.chapterList = res;
          this.noMoreChapter = true;
          this.currentChapter = res[0];
        })
        .catch(() => (this.chapterList = []))
        .finally(() => (this.chapterLoading = false));
    },
    handleChapterPageChange(dir) {
      if (dir === "left") {
        this.chapterPage--;
      } else {
        this.chapterPage++;
      }
      this.getChapterData();
    },
    handlePageChange(dir) {
      if (dir === "left") {
        this.page--;
      } else {
        this.page++;
      }
      this.getBookData();
    },
    getBookData() {
      this.bookLoading = true;
      getBookList(this.accountId, {
        page: this.page,
        title: this.title,
        cbid : this.cbid,
      })
        .then((res) => {
          this.bookList = res.data;
          this.noMoreNovel = res.total_page < this.page;
        })
        .catch(() => (this.bookList = []))
        .finally(() => (this.bookLoading = false));
    },
    handleClose() {
      this.page = 1;
      this.title = "";
      this.currentBook = null;
      this.$emit("close");
    },
    chooseHistoryBook(bookInfo) {
      this.currentBook = {...bookInfo};
      this.handleNext();
    },
    deleteHistory() {
      this.setHistoryBookCache({
        name: "kanShuWang",
        value: [],
      });
    },
  }
  ,
  components: {
    UserAvatar,
  }
  ,
}
;
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;

  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}

.page {
  display: inline-block;
  margin: 0 10px;
}

.input-with-select {
  width: 200px;
  margin-bottom: 20px;
}

.search-history-operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.delete-history {
  cursor: pointer;
}

.search-history-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.dialog-footer {
  // margin-top:40px;
  text-align: center;
}
</style>
