<template>
  <div class="unCallback-box">
    <div class="user-box">
      <p class="title">用户</p>
      <dy-table
        :dataList="userTableData"
        :rowList="userRowList"
        :loading="userLoading"
        :showPagination="true"
        :page="userPage"
        :pageSize="userPageSize"
        :total="userTotal"
        height="650px"
        :row-class-name="handleRowClass"
        @row-click="handleRowClick"
        @pageChange="handleUserPageChange"
      >
        <template #filter>
          <el-button
            style="margin-left: 10px; margin-right: 10px"
            @click="handleUserPageChange(userPage)"
            >刷新</el-button
          >
          <el-input
            style="width: 200px"
            v-model="userSearch.nickname"
            placeholder="用户昵称"
            clearable
            @change="handleUserPageChange(1)"
          ></el-input>
        </template>
        <template #status="{ row }">
          <span v-if="row.status == 0">未上传</span>
          <span v-else-if="row.status == 1">已上传</span>
        </template>
      </dy-table>
    </div>
    <div class="link-box">
      <p class="title">链接</p>
      <dy-table
        :dataList="linkTableData"
        :rowList="linkRowList"
        :loading="linkLoading"
        :showPagination="true"
        :page="linkPage"
        :pageSize="linkPageSize"
        :total="linkTotal"
        height="650px"
        @pageChange="handlelinkPageChange"
      >
        <template #filter>
          <el-button
            style="margin-left: 10px"
            @click="handlelinkPageChange(linkPage)"
            >刷新</el-button
          >
          <el-input
            style="width: 120px; margin-left: 10px"
            v-model="linkSearch.aid"
            placeholder="广告计划id"
            clearable
            @change="handlelinkPageChange(1)"
          ></el-input>

          <el-input
            style="width: 120px; margin-left: 10px"
            v-model="linkSearch.cid"
            placeholder="广告创意id"
            clearable
            @change="handlelinkPageChange(1)"
          ></el-input>
          <el-date-picker
            v-model="selectTime"
            style="width: 330px; margin-left: 10px"
            type="datetimerange"
            align="right"
            unlink-panels
            range-separator="-"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </template>
        <template #status="{ row }">
          <span v-if="row.status == 0">未上传</span>
          <span v-else-if="row.status == 1">已上传</span>
        </template>
        <template #platform="{ row }">
          <span v-if="row.platform == 2">百度</span>
          <span v-else-if="row.platform == 1">头条</span>
        </template>
        <template #operation="{ row }">
          <div style="padding: 0 10px">
            <el-button
              :disabled="!!currentUser.status"
              size="mini"
              type="primary"
              @click="handleCallback(row)"
              :loading="row.loading"
              >付费回传</el-button
            >
          </div>
        </template>
      </dy-table>
    </div>
  </div>
</template>

<script>
import { pickerOptions } from "@/assets/js/options";
import {
  getUnUploadUserList,
  getUnUploadLinkList,
  getUnUploadLinkUpload,
} from "@/api/groundPage";
export default {
  props: {
    detail: Object,
  },
  data() {
    return {
      userSearch: {},
      userPage: 1,
      userPageSize: 15,
      userTotal: 0,
      userTableData: [],
      userLoading: false,
      userRowList: [
        {
          prop: "id",
          label: "id",
          colWidth: 50,
        },
        {
          prop: "nickname",
          label: "昵称",
        },
        {
          prop: "openId",
          label: "openid",
          colWidth: "100",
          popover: true,
        },
        {
          prop: "amount",
          label: "付费金额",
        },
        {
          prop: "registerTime",
          label: "注册时间",
          colWidth: "160",
        },
        {
          prop: "payTime",
          label: "支付时间",
          colWidth: "160",
        },
        {
          prop: "status",
          label: "状态",
          slot: true,
        },
      ],
      currentUser: {},
      // 链接相关
      linkSearch: {},
      linkPage: 1,
      linkPageSize: 15,
      linkTotal: 0,
      linkTableData: [],
      linkLoading: false,
      linkRowList: [
        {
          prop: "id",
          label: "id",
        },
        {
          prop: "ua",
          label: "ua",
          colWidth: "100",
          popover: true,
        },
        {
          prop: "aid",
          label: "计划id",
          colWidth: "90",
          popover: true,
        },
        {
          prop: "cid",
          label: "创意id",
          colWidth: "90",
          popover: true,
        },
        {
          prop: "clickTime",
          label: "访问时间",
          colWidth: "160",
        },
        {
          prop: "status",
          label: "状态",
          slot: true,
        },
        {
          prop: "platform",
          label: "平台",
          slot: true,
        },
        {
          prop: "operation",
          label: "操作",
          colWidth: "140",
          slot: true,
        },
      ],
      selectTime: [],
      pickerOptions,
    };
  },
  watch: {
    selectTime: function (nv) {
      if (nv && nv.length) {
        this.linkSearch.startTime = nv[0];
        this.linkSearch.endTime = nv[1];
      } else {
        this.linkSearch.startTime = null;
        this.linkSearch.endTime = null;
      }
      this.handlelinkPageChange(1);
    },
  },
  methods: {
    handleRowClick(row, column) {
      this.currentUser = row;
      this.handlelinkPageChange(1);
    },
    handleCallback(row) {
      row.loading = true;
      getUnUploadLinkUpload(row.id)
        .then((res) => {
          this.$message.success("操作成功");
          this.handlelinkPageChange();
        })
        .finally(() => {
          row.loading = false;
        });
    },
    handleRowClass({ row, rowIndex }) {
      if (row.id == this.currentUser.id) {
        return "current-row";
      }
    },
    handleUserPageChange(page = this.userPage) {
      this.userPage = page;
      this.userLoading = true;
      getUnUploadUserList({
        landingPageId: this.detail.id,
        nickname: this.userSearch.nickname,
        page: this.userPage,
        pageSize: this.userPageSize,
      })
        .then((res) => {
          this.userTableData = res.list;
          this.userTotal = res.total;
          if (res.total) {
            this.currentUser = this.userTableData[0];
            this.handlelinkPageChange(1);
          } else {
            this.linkPage = 1;
            this.linkTableData = [];
            this.linkTotal = 0;
          }
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    handlelinkPageChange(page = this.linkPage) {
      this.linkPage = page;
      this.linkLoading = true;
      getUnUploadLinkList({
        page: this.linkPage,
        pageSize: this.linkPageSize,
        landingPageId: this.detail.id,
        openid: this.currentUser.openId,
        ...this.linkSearch,
      })
        .then((res) => {
          this.linkTableData = res.list;
          this.linkTotal = res.total;
        })
        .finally(() => {
          this.linkLoading = false;
        });
    },
    handleInit() {
      this.handleUserPageChange();
    },
  },
  created() {
    this.handleInit();
  },
};
</script>

<style lang='scss' scoped>
.unCallback-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .title {
    margin: 10px;
    color: #72767b;
  }
  .user-box {
    width: 42%;
    /deep/.el-table__row {
      cursor: pointer;
    }
  }
  .link-box {
    width: 57%;
    padding-left: 20px;
    border-left: 1px dashed #ccc;
  }
}
</style>