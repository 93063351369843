import request from './config'

// 获取落地页列表
export function getGroundList(params) {
  return request.get('/landingPage', { params })
}

// 添加落地页
export function addGroundList(params) {
  return request.post('/landingPage', params)
}

// 删除落地页
export function deleteGroundList(id) {
  return request.delete(`/landingPage/${id}`)
}

// 修改落地页
export function updateGroundList(id, params) {
  return request.put(`/landingPage/${id}`, params)
}

// 落地页回传明细
export function landingPageDetail(id, params) {
  return request.get(`/landingPage/callback/detail/${id}`, { params })
}

// 落地页回传批量回传
export function uploadLandingPageDetail(id) {
  return request.put(`/landingPage/callback/${id}`)
}

// 落地页上传链接数据获取
export function getLandingUploadLink(params) {
  return request.get(`/virtual/callback/list`, { params })
}

// 落地页上传链接
export function uploadLandingPageLink(params) {
  return request.post(`/virtual/callback`, params)
}

// 百度联调
export function BaiduLandingPageLink(params) {
  return request.get(`/landingPage/jointBaidu`, { params })
}

// 落地页未回传用户列表
export function getUnUploadUserList(params) {
  return request.get(`/user/unUpload`, { params })
}

// 落地页未回传链接列表
export function getUnUploadLinkList(params) {
  return request.get(`/user/unUpload/link`, { params })
}
// 落地页未回传链接付费回传操作
export function getUnUploadLinkUpload(id) {
  return request.post(`/user/unUpload/${id}`)
}
