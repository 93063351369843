<template>
  <div class="back-up-down">
    <i class="el-icon-caret-top is-selected" @click="goUp"></i>
    <i class="el-icon-caret-bottom is-selected" @click="goDowm"></i>
  </div>
</template>
<script>
export default {
  name: "backUpDown",
  props: ["target"],
  data() {
    return {
      direction: "up",
    };
  },
  methods: {
    goUp() {
      this.direction = "up";
      this.scrollTarget("up");
    },
    goDowm() {
      this.direction = "down";
      this.scrollTarget("down");
    },
    // 跳转到制定位置
    scrollTarget(dir) {
      if (dir === "down") {
        this.target.scrollTop = this.target.scrollHeight;
      } else {
        this.target.scrollTop = 0;
      }
    },
    // setPosition() {
    //   return {
    //     positon: "absolute",
    //     top: `${this.top || "80%"}`,
    //     left: `${this.left || "90%"}`,
    //     marginRight: "300px",
    //   };
    // },
  },
};
</script>
<style lang="scss" scoped>
    .back-up-down{
        cursor: pointer;
        position: absolute;
        top:750px;
        left:800px;
        background:#fff;
        box-shadow: 0 0 6px rgba(0,0,0,.12);
        z-index:9999;
        width:50px;
        height:50px;
        border-radius:50%;
        display: flex;
        flex-direction:column;
        justify-content: center ;
        align-items: center;
        font-size:20px;
        i{
            color:#ccc;
        }
        .is-selected{
            color:#1c9399;
        }

    }
</style>
