<template>
  <el-dialog
    title="选择小说"
    :visible.sync="show"
    width="800px"
    top="0vh"
    append-to-body
    v-dialogDrag
    :before-close="handleClose"
  >
    <div>
      <el-form :inline="true">
        <el-form-item label="作品CBID">
          <el-input v-model="cbid" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="作品关键字">
          <el-input v-model="title" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlePageChange(1)"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div class="search-history">
        <div class="search-history-operation" v-if="historySearchList.length">
          <h3>搜索历史</h3>
          <div class="delete-history" @click="deleteHistory">
            <i class="el-icon-delete-solid"></i>
            <span>清除历史记录</span>
          </div>
        </div>
        <div class="search-history-list" v-if="historySearchList.length">
          <div
            v-for="(bookInfo, index) in historySearchList.slice(-5)"
            :key="index"
            style="margin-right: 15px; margin-bottom: 15px"
          >
            <div v-if="'BookName' in bookInfo">
              <el-button
                round
                style="padding: 10px"
                @click="chooseHistoryBook(bookInfo)"
                >{{ bookInfo.BookName }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-table
        :data="bookList"
        height="550px"
        v-loading="bookLoading"
        highlight-current-row
        @current-change="(row) => (this.currentBook = row)"
        style="width: 100%"
      >
        <el-table-column prop="select" label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              type="radio"
              :value="currentBook ? currentBook.cbid : null"
              :label="scope.row.cbid"
              style="font-size: 0"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column property="cbid" label="作品ID" min-width="120">
        </el-table-column>
        <el-table-column property="BookName" label="作品名称" min-width="120">
        </el-table-column>
        <el-table-column property="level" label="评级" width="60">
        </el-table-column>
        <el-table-column property="TopCategoryName" label="一级分类" width="80">
        </el-table-column>
        <el-table-column property="CategoryName" label="二级分类" width="80">
        </el-table-column>
        <el-table-column property="SubCategoryName" label="三级分类" width="80">
        </el-table-column>
        <el-table-column property="bookStatusName" label="连载状态" width="80">
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin: 20px 0; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleNext">下一步</el-button>
      </div>

      <!--选择章节-->
      <el-dialog
        title="选择章节"
        :visible.sync="showNext"
        width="800px"
        top="5vh"
        append-to-body
        :before-close="() => (this.showNext = false)"
      >
        <el-form :inline="true">
          <el-form-item label="作品CBID">
            <el-input
              :value="currentBook ? currentBook.cbid : null"
              style="width: 150px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="作品关键字">
            <el-input
              :value="currentBook ? currentBook.BookName : null"
              style="width: 150px"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          ref="singleTable"
          :data="chapterList"
          height="650px"
          v-loading="chapterLoading"
          highlight-current-row
          @current-change="(row) => (this.currentChapter = row)"
          style="width: 100%"
        >
          <el-table-column prop="select" label="选择" width="50">
            <template slot-scope="scope">
              <el-radio
                type="radio"
                :value="currentChapter ? currentChapter.ccid : null"
                :label="scope.row.ccid"
                style="font-size: 0"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column property="ccid" label="章节ID" width="170">
          </el-table-column>
          <el-table-column
            property="chapterName"
            label="作品名称"
            min-width="120"
          >
          </el-table-column>
          <el-table-column property="free" label="收费情况" width="80">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.free === 0" type="danger">收费</el-tag>
              <el-tag v-else>免费</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="dialog-footer">
          <el-button @click="showNext = false">取 消</el-button>
          <el-button type="primary" @click="handleSure" :loading="submitLoading"
            >确 定</el-button
          >
        </div>

        <!--    <edit-book :show="isEdit" @close="isEdit" @refresh="" />-->
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script>
import { YueWenBook, YueWenChapterData } from "@/mockData/index.js";
import { getBookList, getBookChapter } from "../../../api/service";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import { deduplication } from "./../../../assets/js/utils";
import { mapMutations } from "vuex";
export default {
  name: "insertBook",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    accountId: Number,
    prop: {
      type: String,
      default: "service",
    },
  },
  data() {
    return {
      page: 1,
      total: 0,
      cbid: null,
      title: null,
      bookList: [],
      bookLoading: false,
      currentBook: null,
      showNext: false,
      chapterList: [],
      chapterLoading: false,
      currentChapter: {},
      submitLoading: false,
    };
  },
  computed: {
    historySearchList() {
      const { yueWen = [] } = this.$store.state.historyBookCache || {}
      return [...yueWen]
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.getBookData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setHistoryBookCache"]),
    handleSure() {
      const { ccid, chapterName } = this.currentChapter;
      const { cbid, BookName } = this.currentBook;
      this.submitLoading = true;
      // this.handleClose();
      this.showNext = false;
      // <a href="{booklink:22187493000680502,ccid:96067135545295515}">踏过星光说爱你</a>
      const name = BookName + " " + (ccid ? chapterName : "");
      const url = ccid
        ? `{booklink:${cbid},ccid:${ccid},bookName:${Base64.stringify(
            Utf8.parse(name)
          )}}`
        : `{booklink:${cbid}}`;
      this.$emit("success", {
        link: `<a href="${url}">${name}</a>`,
        name,
        url,
      });
      // 图文素材返回
      let nexChapterIndex =
        this.chapterList.findIndex((item) => {
          return item.id === this.currentChapter.id;
        }) + 1;
      this.$emit("resourceSuccess", {
        book: {
          bookId: this.currentBook.cbid,
          bookName: this.currentBook.BookName,
        },
        chapter: {
          chapterName: chapterName,
          bookArticleId: this.chapterList[nexChapterIndex].ccid,
          curChapterId: this.chapterList[nexChapterIndex - 1].ccid,
          bookArticleIdx: null,
          endIdx:
            this.chapterList.findIndex((item) => {
              return item.id === this.currentChapter.id;
            }) + 1,
        },
      });
      this.handleClose();
      this.submitLoading = false;
    },
    handleNext() {
      if (!this.currentBook) {
        this.$message({
          showClose: true,
          message: "请选择一本书籍!",
          type: "warning",
        });
        return;
      }
      const { cbid } = this.currentBook;
      if (cbid) {
        this.showNext = true;
        // this.chapterLoading = true;
        const historyBookList = this.historySearchList.length
          ? [...this.historySearchList]
          : [];
        historyBookList.push({ ...this.currentBook });

        const res = deduplication(historyBookList);
        this.setHistoryBookCache({
          name: "yueWen",
          value: res,
        });
        getBookChapter(this.accountId, { cbid })
          .then((res) => {
            this.chapterList = res;
            // this.chapterList = YueWenChapterData
            this.currentChapter = this.prop === "service" ? res[0] : {};
            // this.currentChapter = YueWenChapterData[0]
          })
          .catch(() => (this.chapterList = []))
          .finally(() => (this.chapterLoading = false));
      } else {
        this.$message.warning("请选择一篇小说！");
      }
    },
    chooseHistoryBook(bookInfo) {
      this.currentBook = bookInfo;
      this.handleNext();
    },
    handlePageChange(page) {
      this.page = page;
      this.getBookData();
    },
    getBookData() {
      this.bookLoading = true;
      getBookList(this.accountId, {
        page: this.page,
        cbid: this.cbid,
        title: this.title,
      })
        .then((res) => {
          this.bookList = res.data;
          this.total = res.recordsTotal;
          // this.bookList = YueWenBook
        })
        .catch(() => (this.bookList = []))
        .finally(() => (this.bookLoading = false));
    },
    handleClose() {
      this.page = 1;
      this.cbid = null;
      this.title = "";
      this.currentBook = null;
      this.$emit("close");
    },
    deleteHistory() {
      this.setHistoryBookCache({
        name: "yueWen",
        value: [],
      });
    },
  },
  // components:{
  //   EditBook
  // }
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.search-history-operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.delete-history {
  cursor: pointer;
}
.search-history-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  // margin-bottom: 15px;
}
</style>
