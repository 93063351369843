<template>
  <el-dialog
    title="选择小说"
    :visible.sync="show"
    width="800px"
    top="0vh"
    append-to-body
    :before-close="handleClose"
  >
    <div>
      <el-input
        placeholder="作品关键字"
        v-model="title"
        class="input-with-select"
        @keydown.enter.native="handleNovelSearch"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleNovelSearch"
        ></el-button>
      </el-input>
      <div class="search-history">
        <div class="search-history-operation" v-if="historySearchList.length">
          <h3>搜索历史</h3>
          <div class="delete-history" @click="deleteHistory">
            <i class="el-icon-delete-solid"></i>
            <span>清除历史记录</span>
          </div>
        </div>
        <div class="search-history-list" v-if="historySearchList.length">
          <div
            v-for="(bookInfo, index) in historySearchList.slice(-5)"
            :key="index"
            style="margin-right: 15px; margin-bottom: 15px"
          >
            <div v-if="'title' in bookInfo">
              <el-button
                round
                style="padding: 10px"
                @click="chooseHistoryBook(bookInfo)"
                >{{ bookInfo.title }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-table
        :data="bookList"
        height="550px"
        v-loading="bookLoading"
        highlight-current-row
        @current-change="(row) => (this.currentBook = row)"
        style="width: 100%"
      >
        <el-table-column prop="select" label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              type="radio"
              :value="currentBook ? currentBook.id : null"
              :label="scope.row.id"
              style="font-size: 0"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column property="id" label="小说ID" width="120">
        </el-table-column>
        <el-table-column property="title" label="小说名称" min-width="120">
        </el-table-column>
        <el-table-column property="avatar" label="头像" width="100">
          <template slot-scope="scope">
            <user-avatar :size="30" :info="scope.row" name-prop="title" />
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-button :disabled="page === 1" @click="handlePageChange('left')"
          >上一页</el-button
        >
        <span class="page">第 {{ page }} 页</span>
        <el-button @click="handlePageChange('right')" :disabled="noMoreNovel"
          >下一页</el-button
        >
      </div>

      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleNext">下一步</el-button>
      </div>

      <!--选择章节-->
      <el-dialog
        title="选择章节"
        :visible.sync="showNext"
        width="800px"
        top="5vh"
        append-to-body
        :before-close="() => (this.showNext = false)"
      >
        <!-- <el-input
        placeholder="作品关键字"
        v-model="chapterTitle"
        class="input-with-select"
        @keydown.enter.native="handleSearch"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input> -->
        <el-table
          :data="chapterList"
          height="650px"
          v-loading="chapterLoading"
          highlight-current-row
          @current-change="(row) => (this.currentChapter = row)"
          style="width: 100%"
        >
          <el-table-column prop="select" label="选择" width="50">
            <template slot-scope="scope">
              <el-radio
                type="radio"
                :value="currentChapter ? currentChapter.id : null"
                :label="scope.row.id"
                style="font-size: 0"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column property="id" label="章节ID" width="170">
          </el-table-column>
          <el-table-column
            property="chaptername"
            label="章节名称"
            min-width="120"
          >
          </el-table-column>
          <!-- <el-table-column property="idx" label="章节下标" width="100">
        </el-table-column> -->
        </el-table>
        <div class="pagination">
          <el-button
            :disabled="chapterPage === 1"
            size="mini"
            @click="handleChapterPageChange('left')"
            >上一页</el-button
          >
          <span class="page">第{{ chapterPage }}页</span>
          <el-button
            size="mini"
            @click="handleChapterPageChange('right')"
            :disabled="noMoreChapter"
            >下一页</el-button
          >
        </div>

        <div class="dialog-footer">
          <el-button @click="showNext = false">取 消</el-button>
          <el-button type="primary" @click="handleSure" :loading="submitLoading"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script>
import UserAvatar from "../../../components/UserAvatar";
import { getBookList, getBookChapter } from "../../../api/service";
import { deduplication } from "./../../../assets/js/utils";
import { mapMutations } from "vuex";

export default {
  name: "insertBook",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    accountId: Number,
  },
  data() {
    return {
      page: 1,
      chapterPage: 1,
      chapterTitle: null,
      title: null,
      bookList: [],
      bookLoading: false,
      currentBook: null,
      showNext: false,
      chapterList: [],
      chapterLoading: false,
      currentChapter: {},
      submitLoading: false,
      noMoreNovel: false,
      noMoreChapter: false,
    };
  },
  computed: {
    historySearchList() {
      const { youShuGe = [] } = this.$store.state.historyBookCache || {}
      return [...youShuGe]
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.handleNovelSearch();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setHistoryBookCache"]),
    handleNovelSearch() {
      this.page = 1;
      this.getBookData();
    },
    handleSure() {
      const { id, chaptername } = this.currentChapter;
      const { title } = this.currentBook;
      if (id) {
        this.submitLoading = true;
        const name = chaptername;
        this.$emit("success", {
          link: `<a href="{articleId:${id}}">${title} ${name}</a>`,
          name,
          bookTitle: title,
          url: `{articleId:${id}}`,
        });
        // 图文素材返回
        // 传下一章下标
        let nexChapterIndex =
          this.chapterList.findIndex((item) => {
            return item.id === this.currentChapter.id;
          }) + 1;
        this.$emit("resourceSuccess", {
          book: {
            bookId: this.currentBook.id,
            bookName: this.currentBook.title,
          },
          chapter: {
            bookArticleId: this.chapterList[nexChapterIndex].id,
            curChapterId: this.chapterList[nexChapterIndex - 1].id,
            chapterName: name,
            endIdx:
              this.chapterList.findIndex((item) => {
                return item.id === this.currentChapter.id;
              }) + 1,
          },
        });
        this.submitLoading = false;
        this.handleClose();
        this.showNext = false;
      } else {
        this.$message.warning("请选择小说章节");
      }
    },
    handleNext() {
      if (this.currentBook) {
        this.chapterTitle = null;
        this.showNext = true;
        const historyBookList = this.historySearchList.length
          ? [...this.historySearchList]
          : [];
        historyBookList.push({ ...this.currentBook });
        const res = deduplication(historyBookList);
        this.setHistoryBookCache({ name: "youShuGe", value: res });
        this.handleSearch();
      } else {
        this.$message.warning("请选择一篇小说！");
      }
    },
    handleSearch() {
      this.chapterPage = 1;
      this.getChapterData();
    },
    getChapterData() {
      this.chapterLoading = true;
      getBookChapter(this.accountId, {
        cbid: this.currentBook.id,
        page: this.chapterPage,
        title: this.chapterTitle,
      })
        .then((res) => {
          this.chapterList = res;
          this.noMoreChapter = res.length < 20;
          this.currentChapter = res[0];
        })
        .catch(() => (this.chapterList = []))
        .finally(() => (this.chapterLoading = false));
    },
    handleChapterPageChange(dir) {
      if (dir === "left") {
        this.chapterPage--;
      } else {
        this.chapterPage++;
      }
      this.getChapterData();
    },
    handlePageChange(dir) {
      if (dir === "left") {
        this.page--;
      } else {
        this.page++;
      }
      this.getBookData();
    },
    getBookData() {
      this.bookLoading = true;
      getBookList(this.accountId, {
        page: this.page,
        title: this.title,
      })
        .then((res) => {
          this.bookList = res.list;
          this.noMoreNovel = res.list.length < 20;
        })
        .catch(() => (this.bookList = []))
        .finally(() => (this.bookLoading = false));
    },
    chooseHistoryBook(bookInfo) {
      this.currentBook = { ...bookInfo };
      this.handleNext();
    },
    handleClose() {
      this.page = 1;
      this.title = "";
      this.currentBook = null;
      this.$emit("close");
    },
    deleteHistory() {
      this.setHistoryBookCache({ name: "youShuGe", value: [] });
    },
  },
  components: {
    UserAvatar,
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.search-history-operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.delete-history {
  cursor: pointer;
}
.search-history-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}

.page {
  display: inline-block;
  margin: 0 10px;
}

.input-with-select {
  width: 200px;
  margin-bottom: 20px;
}
.dialog-footer {
  text-align: center;
}
</style>
