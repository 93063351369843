<template>
  <div class="ground-content">
    <div
      class="ground-table border-radius-6 box-shadow-light-grey padding-20"
      style="padding-top: 16px"
    >
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        :loading="loading"
        :showPagination="true"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        height="650px"
        @pageChange="handlePageChange"
      >
        <template #filter>
          <el-button class="margin-right-ten" @click="handlePageChange"
            >刷新数据</el-button
          >
          <el-input
            v-model="aid"
            style="width: 200px; margin-left: 10px"
            placeholder="广告计划id"
            clearable
            @clear="aid = null"
          >
            ></el-input
          >
          <el-input
            v-model="cid"
            style="width: 200px; margin-left: 10px"
            placeholder="广告创意 id"
            clearable
            @clear="cid = null"
          >
            ></el-input
          >
          <el-button
            type="primary"
            class="margin-left-ten"
            @click="handlePageChange"
            >搜索</el-button
          >
        </template>
        <template #operation="{ row }">
          <el-button
            type="primary"
            size="small"
            class="button-small"
            @click="handleUploadSingle(row.id, 2)"
            >付费上传</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="button-small"
            @click="handleUploadSingle(row.id, 0)"
            >激活上传</el-button
          >
        </template>
      </dy-table>
    </div>
    <el-dialog
      append-to-body
      title="选择类型"
      :visible.sync="showUpload"
      width="270px"
    >
      <div class="type-box">
        <el-select v-model="type" placeholder="请选择类型">
          <el-option label="激活" :value="0"> </el-option>
          <el-option label="付费" :value="2"> </el-option>
        </el-select>
      </div>
      <span slot="footer">
        <el-button @click="showUpload = false">取消</el-button>
        <el-button type="primary" @click="handleUploadSingle">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getLandingUploadLink, uploadLandingPageLink } from "@/api/groundPage";
export default {
  props: ["detail", "landingType"],
  data() {
    return {
      loading: false,
      rowList: [
        {
          label: "id",
          prop: "id",
          colWidth: "60",
        },
        {
          label: "ip",
          prop: "ip",
          colWidth: "160",
        },
        {
          label: "ua",
          prop: "ua",
          colWidth: "360",
          popover: true,
        },
        {
          label: "创意id",
          prop: "cid",
        },
        {
          label: "计划id",
          prop: "aid",
          renderHeader: () => <span id="aidName">计划id</span>,
        },
        {
          label: "创建时间",
          prop: "createdTime",
          colWidth: "160",
          popover: true,
        },
        {
          label: "操作",
          colWidth: "200",
          action: "operation",
        },
      ],
      dataList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      accountChannelId: null,
      showEdit: false,
      isEdit: false,
      formParams: {},
      editId: null,
      timeRange: ["09:00:00", "22:00:00"],
      deleteIds: [],
      params: {},
      deleteIds: [],
      type: 0,
      currentId: null,
      showUpload: false,
      cid: null,
      aid: null,
      cidName: "计划id",
    };
  },
  methods: {
    handleShowUpload(id) {
      this.currentId = id;
      this.showUpload = true;
    },
    handleUploadSingle(id, type) {
      uploadLandingPageLink({
        id: id,
        type: type,
      }).then((res) => {
        this.$message.success("上传成功");
        this.handlePageChange();
      });
    },
    handlePageChange(page) {
      this.loading = true;
      this.page = typeof page === "number" ? page : this.page;
      getLandingUploadLink({
        page: this.page,
        pageSize: this.pageSize,
        landingPageId: this.detail.id,
        cid: this.cid,
        aid: this.aid,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.landingType == 2) {
      this.cidName = "单元id";
    }
    this.handlePageChange();
  },
  mounted() {
    // 根据 type修改计划id或单元id
    this.$nextTick(() => {
      document.querySelector("#aidName").innerHTML = this.cidName;
    });
  },
};
</script>

<style lang="scss" scoped>
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
.ground-content {
  height: 100%;
  .operation {
    background: #fff;
    box-sizing: border-box;
    // padding:  0 20px;
  }
  .ground-table {
    background: #fff;
  }
}
</style>
