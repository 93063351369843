<template>
  <div class="self-upload">
      <input
        type="file"
        ref="file"
        multiple
        @change="fileChange"
        name="file"
        accept="image/*"
        class=""
      />
      <span class='replace-inputFileStyle'>点击上传图片</span>
    <!-- <div class="el-upload el-upload--picture-card" :style="uploadSize">
      <div
        class="el-upload-dragger"
        @click="$refs.file.click()"
        v-loading="loading"
      >
        <img :src="url" v-if="url" class="single-img" />
        <i class="el-icon-plus avatar-uploader-icon" v-else></i>
        <div class="el-upload-list--picture-card" v-if="url">
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-delete">
              <em class="el-icon-delete" @click.stop="delFile"></em>
            </span>
          </span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { uploadFile } from '../api/common'

export default {
  name: 'uploadImage',
  model: {
    prop: 'url',
    event: 'uploadSuccess'
  },
  data () {
    return {
      loading: false,
      files: null
    }
  },
  props: {
    width: {
      type: String,
      default: '120'
    },
    url: {
      default: '',
      type: String
    },
    height: {
      type: String,
      default: '120'
    }
  },
  computed: {
    uploadSize () {
      return { width: `${this.width}px`, height: `${this.height}px` }
    }
  },
  methods: {
    delFile () {
      this.$emit('uploadSuccess', '')
      this.$refs.file.value = null
    },
    fileChange (e) {
      // const file = e.srcElement.files[0]
      // !修改上传方式 支持多张图片上传
      const fileList = e.srcElement.files
      this.files = e.srcElement.files
      const fileLength = fileList.length
      if (fileLength > 6) {
        this.$message({
          showClose: true,
          message: '单次最多上传6张图片!',
          type: 'warning'
        })
        return
      }
      // this.testPreView()
      for (let i = 0; i < fileLength; i++) {
        const file = fileList[i]
        if (file) {
          if (file.size > 1024 * 1024 * 1.5) {
            this.$message.warning('图片大小不能超过1.5M，请重新选择图片')
            return false
          }
          this.loading = true
          uploadFile({ file })
            .then(res => {
              this.$emit('uploadSuccess', res)
            })
            .finally(() => {
              this.$refs.file.value = null
              this.loading = false
            })
        }
      }
    }
    // testPreView(){
    //   this.files.forEach(item=>{
    //     let reader = new FileReader();
    //     reader.readAsDataURL(item)
    //     reader.onprogress = (e)=>{
    //       ;
    //       ;
    //       if(e.lengthComputable){

    //       }
    //     };
    //     reader.onload = (e) =>{
    //       let image = new Image();
    //       image.src = e.target.result;
    //       document.querySelector('.preView').appendChild(image)
    //     }
    //   })
    // }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.self-upload {
  width:100%;
  overflow: hidden;
  line-height: 40px;
  margin-top: -8px;
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}
input[type=file]{
  position: absolute;
  top:0;
  left:0;
  opacity: 0;
  width:104px;
  height:40px;
}
.replace-inputFileStyle{
  display: inline-block;
  cursor: pointer;
  font-size:14px;
  color:#fff;
  background:#00bf8a;
  border-radius: 4px;
  padding:0 10px;
}

.self-upload .el-upload--picture-card {
  position: relative;
  line-height: unset;
  margin-top: 8px;
  border: 1px solid #ececec;
}

.self-upload .el-upload-dragger {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-img,
.self-upload .el-upload-list--picture-card {
  width: 100%;
  height: 100%;
}

.self-upload .upload-list__item-change {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 24px;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.upload-tip {
  color: #999;
  line-height: 30px;
  font-size: 14px;
}

.self-upload .el-upload-list__item-actions {
  font-size: 18px;
}

.self-upload .el-icon-delete:hover,
.self-upload .el-icon-zoom-in:hover {
  transform: scale(1.2);
}

.self-upload .el-icon-delete,
.self-upload .el-icon-zoom-in {
  transition: 0.3s;
}
</style>
