<template>
  <el-dialog
    title="选择小说"
    :visible.sync="show"
    width="800px"
    top="0vh"
    v-dialogDrag
    append-to-body
    :before-close="handleClose"
  >
    <div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="作品CBID">
          <el-input v-model="cbid" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="作品关键字">
          <el-input v-model="title" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlePageChange(1)"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div class="search-history">
        <div class="search-history-operation" v-if="historySearchList.length">
          <h3>搜索历史</h3>
          <div class="delete-history" @click="deleteHistory">
            <i class="el-icon-delete-solid"></i>
            <span>清除历史记录</span>
          </div>
        </div>
        <div class="search-history-list" v-if="historySearchList.length">
          <div
            v-for="(bookInfo, index) in historySearchList.slice(-5)"
            :key="index"
            style="margin-right: 15px; margin-bottom: 15px"
          >
            <div>
              <el-button
                round
                style="padding: 10px"
                @click="chooseHistoryBook(bookInfo)"
                >{{ bookInfo.title }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-table
        :data="bookList"
        height="550px"
        v-loading="bookLoading"
        @current-change="(row) => (this.currentBook = row)"
        style="width: 100%"
      >
        <el-table-column prop="select" label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              type="radio"
              :value="currentBook ? currentBook.bookId : null"
              :label="scope.row.bookId"
              style="font-size: 0"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column property="bookId" label="书籍ID" width="120">
        </el-table-column>
        <el-table-column
          property="iconUrl"
          align="center"
          label="图片"
          width="100"
        >
          <template slot-scope="scope">
            <img :src="scope.row.iconUrl" alt="" class="book-img" />
          </template>
        </el-table-column>
        <el-table-column property="name" label="小说名称" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.title + "/" + scope.row.viewTitle }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 15px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-count="totalPage"
        :page-size="20"
        layout="total, prev, pager, next, jumper"
      >
      </el-pagination>

      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleNext">下一步</el-button>
      </div>

      <!--选择章节-->
      <el-dialog
        title="选择章节"
        :visible.sync="showNext"
        width="800px"
        top="5vh"
        append-to-body
        :before-close="() => (this.showNext = false)"
      >
        <!--      <el-input placeholder="作品关键字" v-model="chapterTitle" class="input-with-select"-->
        <!--                @keydown.enter.native="handleSearch">-->
        <!--        <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>-->
        <!--      </el-input>-->
        <el-table
          :data="chapterList"
          height="600px"
          v-loading="chapterLoading"
          highlight-current-row
          @current-change="(row) => (this.currentChapter = row)"
          style="width: 100%"
        >
          <el-table-column prop="select" label="选择" width="50">
            <template slot-scope="scope">
              <el-radio
                type="radio"
                :value="currentChapter ? currentChapter.id : null"
                :label="scope.row.id"
                style="font-size: 0"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column property="id" label="章节ID" width="170">
          </el-table-column>
          <el-table-column property="title" label="章节名称" width="150">
            </el-table-column>
          <el-table-column property="needPay" label="资费" width="70">
            <template slot-scope="scope">
              {{scope.row.needPay?'收费':'免费'}}
            </template>
          </el-table-column>
          <el-table-column property="sourceUuid" label="资源id">
            </el-table-column>
          <!-- <el-table-column property="idx" label="章节下标" width="100"> -->
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 15px; text-align: right"
          background
          @current-change="handleChapterPageChange"
          :current-page="chapterPage"
          :total="chapterTotal"
          :page-size="chapterPageSize"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>
        <div class="dialog-footer">
          <el-button @click="showNext = false">取 消</el-button>
          <el-button type="primary" @click="handleSure" :loading="submitLoading"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script>
import { getBookList, getBookChapter } from "../../../api/service";
import { deduplication } from "./../../../assets/js/utils";
import { mapMutations } from "vuex";

export default {
  name: "insertBook",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    accountId: Number,
  },
  data() {
    return {
      page: 1,
      total: 0,
      totalPage: 1,
      // chapterPage: 1,
      // chapterTitle: null,
      title: null,
      cbid: null,
      bookList: [],
      bookLoading: false,
      currentBook: null,
      showNext: false,
      chapterList: [],
      chapterListTotal: [],
      chapterPage: 1,
      chapterTotal: 0,
      chapterPageSize: 15,
      chapterLoading: false,
      currentChapter: {},
      submitLoading: false,
      // noMoreChapter: false
    };
  },
  computed: {
    historySearchList() {
      const { wangYi = [] } = this.$store.state.historyBookCache || {}
      return [...wangYi]
    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.handlePageChange(1);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setHistoryBookCache"]),
    handleChapterPageChange(page) {
      this.chapterPage = page;
      this.chapterList = this.chapterListTotal.slice(
        (this.chapterPage - 1) * this.chapterPageSize,
        this.chapterPage * this.chapterPageSize
      );
    },
    handleSure() {
      // return;
      const { id, name, sourceUuid, title } = this.currentChapter;
      const bookName = this.currentBook.title + "-" + title;
      if (id) {
        this.submitLoading = true;
        // {booklink:11010058559,ccid:473126518,idx:3}
        // {sourceUuid:f2fa6288d814404eba66dd782eb264bb_4 ,ccid:179f9f5f6a1146eda19ca8e913c48956_4 ,bookName:商梯-第1章差两万}
        const link = `{sourceUuid:${sourceUuid},ccid:${id},bookName:${bookName}}`;
        this.$emit("success", {
          link: `<a href = "${link}">${bookName}</a>`,
          name: bookName,
          url: link,
        });
        // 图文素材返回
        let nexChapterIndex =
          this.chapterList.findIndex((item) => {
            return item.id === this.currentChapter.id;
          }) + 1;
        this.$emit("resourceSuccess", {
          book: {
            bookId: this.currentBook.bookId,
            bookName: this.currentBook.title,
          },
          chapter: {
            chapterName: name,
            bookArticleId: this.chapterList[nexChapterIndex].id,
            // bookArticleIdx: this.chapterList[nexChapterIndex].idx,
            curChapterId: this.chapterList[nexChapterIndex - 1].id,
            sourceUuid: this.chapterList[nexChapterIndex - 1].sourceUuid,
            endIdx:
              this.chapterList.findIndex((item) => {
                return item.id === this.currentChapter.id;
              }) + 1,
          },
        });
        this.submitLoading = false;
        this.handleClose();
        this.showNext = false;
      } else {
        this.$message.warning("请选择小说章节");
      }
    },
    handleNext() {
      if (this.currentBook) {
        // this.chapterTitle = null;
        this.showNext = true;
        const historyBookList = this.historySearchList.length
          ? [...this.historySearchList]
          : [];
        // historyBookList.push({ ...this.currentBook });
        let tmpFlag = historyBookList.some((item) => {
          return item.bookId === this.currentBook.bookId;
        });
        if (!tmpFlag) {
          historyBookList.push({ ...this.currentBook });
          this.setHistoryBookCache({
            name: "wangYi",
            value: historyBookList,
          });
        }

        this.handleSearch();
      } else {
        this.$message.warning("请选择一篇小说！");
      }
    },
    handleSearch() {
      // this.chapterPage = 1;
      this.getChapterData();
    },
    getChapterData() {
      this.chapterLoading = true;
      getBookChapter(this.accountId, {
        cbid: this.currentBook.bookId,
        // page: this.chapterPage,
        // title: this.chapterTitle
      })
        .then((res) => {
          this.chapterListTotal = res;
          this.chapterTotal = res.length;
          this.chapterList = this.chapterListTotal.slice(
            (this.chapterPage - 1) * this.chapterPageSize,
            this.chapterPage * this.chapterPageSize
          );
          // this.noMoreChapter = res.length < 20;
          this.currentChapter = res[0];
        })
        .catch(() => {
          this.chapterList = [];
        })
        .finally(() => {
          this.chapterLoading = false;
        });
    },
    // handleChapterPageChange(dir) {
    //   if (dir === 'left') {
    //     this.chapterPage--;
    //   } else {
    //     this.chapterPage++;
    //   }
    //   this.getChapterData();
    // },
    handlePageChange(page) {
      this.page = page;
      this.getBookData();
    },
    chooseHistoryBook(bookInfo) {
      this.currentBook = bookInfo;
      this.handleNext();
    },
    getBookData() {
      this.bookLoading = true;
      getBookList(this.accountId, {
        page: this.page,
        title: this.title,
        cbid: this.cbid,
      })
        .then((res) => {
          this.bookList = res.list;
          this.totalPage = res.totalPage;
        })
        .catch(() => {
          this.bookList = [];
        })
        .finally(() => {
          this.bookLoading = false;
        });
    },
    handleClose() {
      this.page = 1;
      this.title = null;
      this.cbid = null;
      this.currentBook = null;
      this.$emit("close");
    },
    deleteHistory() {
      this.setHistoryBookCache({
        name: "wangYi",
        value: [],
      });
    },
  },
};
</script>

<style lang='scss' scoped>
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}
.search-history-operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.delete-history {
  cursor: pointer;
}
.search-history-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  // margin-bottom: 15px;
}

.page {
  display: inline-block;
  margin: 0 10px;
}

.book-img {
  height: 50px;
  width: auto;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
