<template>
  <el-avatar v-if="info[avatarProp]" :src="info[avatarProp]" :size="size" :shape="shape" :fit="fit" :style="styles" />
  <el-avatar v-else-if="info[nameProp]" class="text-background"  :style="styles"
             :size="size" :shape="shape" :fit="fit" >{{info[nameProp]}}</el-avatar>
  <el-avatar v-else icon="el-icon-user-solid" class="text-background"  :style="styles"
             :size="size" :shape="shape" :fit="fit"></el-avatar>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    size: {
      type: Number,
      default: 40
    },
    shape: {
      type: String,
      default: 'circle'
    },
    fit: {
      type: String,
      default: 'cover'
    },
    avatarProp: {
      type: String,
      default: 'avatar'
    },
    nameProp: {
      type: String,
      default: 'name'
    },
    info: Object,
    styles: Object
  }
}
</script>

<style scoped>
  .text-background{
    background-color: #f7faf8;
    color: rgb(28, 147, 153);
  }

</style>
